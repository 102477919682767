@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  font-family: "Mukta", sans-serif !important;
  -ms-overflow-style: none !important;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.text-16 {
  font-size: 16px !important;
}

.text-14 {
  font-size: 14px !important;
}

.custom-mb-break div{
  max-width: 400px !important
}

li::marker {
  font-size: 0.7em;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-thumb {
  background: #dcdcdc;
  border-radius: 10px;
}

.md-hidden {
  @media (min-width: 960px) {
    display: none !important;
  }
}
.video-embed-field-provider-youtube iframe{
  min-height: 205px;
  width: -webkit-fill-available;
  height: 100px;
}

.desktop-only{
  display: block;
}
.desktop-only-flex{
  display: flex;
  justify-content: center;
}
.mobile-only{
  display: none;
}
@media screen and (max-width: 786px) {
  .desktop-only{
    display: none;
  }
  .mobile-only{
    display: block;
  }
  .desktop-only-flex{
    flex-direction: column;
  }
}
.whatsapp-sticky{
  background: #5db24d;
    width: 35%;
    height: 2.2rem;
    color: #fff;
    border-radius: 100px;
    text-align: center;
    /* padding: 3px 3%; */
    position: fixed;
    bottom: 17%;
    z-index: 9999;
    left: 31%;
    filter: drop-shadow(4px 9px 6px rgba(0, 0, 0, 0.5));
    padding-left: 1rem;
    padding-top: 5px;
}
.googlenews-sticky{
  position: fixed;
  bottom: 40%;
  right: 0;
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,.2));
  
  z-index: 9999;
}
.googlenews-sticky img{
  border-radius: 10px 0 0 10px;
}
.whatsapp-sticky span{
  float: left;
    font-size: 14px;
    font-weight: 700;
    margin: 3px 0 0 0;
    text-transform: uppercase;
}
.whatsapp-sticky img{
  float: right;
}
.whatsapp-sticky a{
  gap: 1rem;
}
.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-none {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.scroll-margin-100{
  scroll-margin: 100px;
}


.custom-iframe>iframe{
  max-width: 300px;
  max-height: 220px!important;
  --aspect-ratio-x: 16;
  --aspect-ratio-y: 9;
}
.top-layer-election .custom-iframe>iframe{
  max-width: 100%!important;
  position: sticky;
}

.custom-z{
  z-index: 1!important;
  position: relative;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.imgDescription h2{
  font-size: 20px;
  margin-bottom: 0.5rem;
}
.imgDescription h1{
  font-size: 20px;
}
.imgDescription p{
  font-size: 17px;
}

.imgDescriptionView h2{
  font-size: 20px;
  margin-bottom: 0.08rem;
}
.imgDescriptionView h1{
  font-size: 20px;
}
.imgDescriptionView p{
  font-size: 17px;
}