.MainContainer{
    padding: 5px;
    border: 1px solid #E2E2E2;
    border-top: 4px solid #E2E2E2;
    border-radius: 4px;
    display: flex;
}
.LeftDiv{
    width: 4%;
    min-width: 36px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.RightDiv{
    width: 4%;
    min-width: 36px;
    justify-content: center;
    display: flex;
    align-items: center;
}
.CenterDiv{
    width: 92%;
    display: flex;
    overflow-x: hidden;
}
 .AgencyDiv{
    display: grid;
    min-width: 50%;
    padding-top: 8px;
}
    .AgencyDiv2{
    display: grid;
    min-width: 100%;
    padding-top: 8px;
}
/* @media (max-width: 1100px) {
    .AgencyDiv {
        min-width: 50%;
    }
}  */
@media (max-width: 700px) {
    .AgencyDiv {
        min-width: 100%;
    }
}
.AgencyNameDiv{
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
}
.PartyDataDiv{
    display: flex;
    padding-bottom: 8px;
    width: 94%;
    justify-self: center;
}
.PartyNameDiv{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: left;
}
.PartySeatDiv{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: right;
}
.SeatSlider{
    background-color: #E7E6E4;
    border-radius: 8px;
    display: flex;
    width: 94%;
    height: 14px;
    justify-self: center;
    margin-bottom: 14px;
}
.WinningSeat{
    width: 0px;
    transition: '1s all';
}
.OuterMain {
    padding: 10px;
    border-radius: 1px solid #8c8484;
}